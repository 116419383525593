.slide {
  animation: slideToLeft 30s linear infinite;
  height: 187px;
  @media (min-width: 768px) {
    height: 288px;
  }
}

@keyframes slideToLeft {
  100% {
    transform: translateX(calc(-336px * 6));
  }
}

.img {
  height: 187px;
  width: 187px;
  @media (min-width: 768px) {
    height: 288px;
    width: 288px;
  }
}
