.subTitle {
  font-size: 25.88px;
}
.goDown {
  &::before {
    position: absolute;
    content: '';
    background-color: #79ff75;
    opacity: 0.5;
    width: 1px;
    bottom: -186px;
    height: 186px;
  }
}
.last {
  @media (min-width: 768px) {
    &::before {
      display: none;
    }
  }
}
