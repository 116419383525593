.input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: white;
    opacity: 1;
  }
}
.dateBlock {
  width: 65px;
  height: 91px;
  @media (min-width: 768px) {
    width: 91px;
  }
}
.gradient {
  background: linear-gradient(90deg, #79ff75 0%, #ce6de9 100%);
  position: relative;
}
.antiGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  // width: calc(100% - 100 / 1500 * 100%);
  background-color: #22304a;
  overflow: hidden;
}
.link {
  color: #79ff75;
  width: 185px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  border: 1px solid #79ff75;
  border-radius: 8px;
  display: inline-block;
  font-weight: 900;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #79ff75;
    color: black;
  }
  @media (min-width: 1280px) {
    width: 168px;
  }
}
.brandBtn {
  background-color: #79ff75;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: lighten(#79ff75, 5%);
  }
}
.storeBtn {
  width: 100%;
  height: 62px;
  text-align: center;
  line-height: 62px;
  color: black;
  border-radius: 8px;
  font-weight: 900;
  @media (min-width: 768px) {
    width: 203px;
  }
}
