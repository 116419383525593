.link {
  color: #79ff75;
  padding: 22px;
  text-align: center;
  border: 1px solid #79ff75;
  border-radius: 8px;
  font-weight: 900;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #79ff75;
    color: black;
  }
}
.grid {
  width: 100%;
  max-width: 284px;
}
.socialLink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.linkGroup {
  border: 1px solid #d0d3da;
  border-radius: 6px;
  height: 44px;
  width: 100%;
}
.borderLeft {
  border-left: 1px solid #d0d3da;
}
.openseaIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 4px;
  @media (min-width: 1024px) {
    width: 22px;
    height: 22px;
  }
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 768px) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @media (min-width: 1240px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.astoWrapMaxWidth {
  @media (min-width: 768px) {
    max-width: 640px;
  }
  @media (min-width: 1024px) {
    max-width: 896px;
  }
  @media (min-width: 1440px) {
    max-width: inherit;
  }
}
